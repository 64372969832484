import React, { Component } from 'react';
import './App.css';
import { CSSTransitionGroup } from 'react-transition-group'


class App extends Component {
  constructor(props){
    super(props);
    this.state={
      ask: 'initial',
    }
  }

  passAsk = (passAskVal) => {
    this.setState({ask: passAskVal})
  }

  render() {
    return (
      <div className="App">

      <UniverseSVG />
      <div className='logo'>
      </div>
      <div className='screen'>


      <div className='ask'>
      <Ask askChange={this.passAsk}/>
      </div>


      <div className='say'>
      <Say sayVal={this.state.ask}/>
      </div>


      </div>
      </div>
    );
  }
}



class Ask extends Component{
  constructor(props){
    super(props);
    this.state={
      meep: 0, //dropdown for projects
      show: false,
    }
  }

  render(){
    if(this.state.meep===0)
    return(
      <div id='nav'>

      <h2 onClick={()=> {this.props.askChange('initial')}}>HalcyonWeave</h2>
      <h1 className="accordion" onClick={()=> {this.setState({meep:1})}}>Projects</h1>
      <h1 className="accordion" onClick={()=> {this.props.askChange('principles')}}>Principles</h1>
      <h1 className="panel3 accordion" onClick={()=> {this.props.askChange('availability')}}>Availability</h1>

      </div>
    );
    else if(this.state.meep===1)
    return(
      <div id='nav'>

      <h2 onClick={()=> {this.props.askChange('initial')}}>HalcyonWeave</h2>
      <h1 className="accordion" onClick={()=> {this.setState({meep:0})}}>Projects</h1>
      <div className="panel2">
      <h3 onClick={()=> {this.props.askChange('engulf')}}>Engulf</h3>
      <h3 onClick={()=> {this.props.askChange('cildecor')}}>CIL Decor</h3>
      </div>
      <h1 className="accordion" onClick={()=> {this.props.askChange('principles')}}>Principles</h1>
      <h1 className="panel3 accordion" onClick={()=> {this.props.askChange('availability')}}>Availability</h1>

      </div>
    );
  }
}

class Say extends Component{
  render(){
    if(this.props.sayVal==='engulf')
    return(
      <div>

      <h3>Engulf</h3>
      <p>General purpose inventory management web application accessible from any number of phones, tablets or PCs. New features like incoming/outgoing bulk edit for shipments are in the works.</p>
      <a href='https://engulf.halcyonweave.com' target="_blank" rel="noopener noreferrer">Live demo</a><br/>

      </div>
    );
    else if(this.props.sayVal==='cildecor')
    return(
      <div>

      <h3>CIL Decor</h3>
      <p>Presentation page for a wallpaper mounting business</p>
      <p>The creation process involved complete web page design with information structuring, google fonts setup,
      color pallete selection and distribution, considerable video and image editing in addition to domain, webmail and hosting setup</p>
      <a href='http://www.cildecor.com' target="_blank" rel="noopener noreferrer">Live page</a><br/>

      </div>
    );
    else if(this.props.sayVal==='principles')
    return(
      <div>

      <h3>Generate positive change</h3>
      <h3>Communicate plainly</h3>
      <h3>Build to last</h3>
      <h3>Simple yet thorough</h3>
      <p></p>

      </div>
    );
    else if(this.props.sayVal==='availability')
    return(
      <div className='availability'>
      <p>Currently available.</p>
      <p>We're also undertaking pro-bono work. If your project is positively changing the world, we might jump in and help, give us a shout!</p>
      <p>contact@halcyonweave.com</p>

      </div>
    );
    else return(
      <div>
      <CSSTransitionGroup
        transitionName="init1"
          transitionAppear={true}
          transitionAppearTimeout={3000}
          transitionEnter={false}
          transitionLeaveTimeout={3000}>
    <h1 className='splash'>Think ahead,  <br/>
    Build to last.
    </h1>
    </CSSTransitionGroup>

    </div>
  );
  }
}


  class UniverseSVG extends Component{
    render() {
      return (
        <div className='universe'>
        <svg version="1.1" viewBox="0 0 555.13 338.18">
        <path d="m136.46 220.8-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m147.04 209.84-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m143.54 190.94-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m168.21 198.88-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m159.04 192.27-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m131.37 190.55-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m128.16 207.66-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m101.43 225.3-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m63.48 242.41-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m48.513 271.27-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m29.269 296.39-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m73.102 284.1-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m86.465 268.6-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m73.636 256.3-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m99.294 246.15-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m119.07 242.94-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m124.42 223.7-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m158.63 215.14-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m182.69 192.69-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m212.08 179.33-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m239.88 157.95-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m266.07 142.98-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m296.01 132.82-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m328.61 120.53-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m381 99.68-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m396.5 83.109-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m409.33 63.866-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m406.12 33.932-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m420.02 28.052-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m447.28 25.913-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m457.97 26.448-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m415.21 49.968-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m387.95 58.521-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m353.2 66.539-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m293.87 97.542-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m247.9 125.88-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m214.22 142.45-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m195.51 158.48-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m170.39 172.38-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m157.56 180.93-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m184.29 175.05-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m216.9 162.22-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m240.42 145.12-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m262.33 131.76-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m293.33 116.78-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m328.61 105.56-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m369.77 87.92-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m374.05 76.695-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m343.58 84.178-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m328.08 86.851-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m310.97 101.82-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m304.03 122.13-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m275.16 129.08-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m267.14 119.99-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m277.83 105.56-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m460.65 122.13-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m390.09 165.97-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m329.68 172.91-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m430.71 222.09-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m472.41 190.55-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m488.44 232.25-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m433.38 245.08-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m380.46 267.53-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m352.13 279.82-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m297.61 292.65-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m196.05 301.2-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m137.25 278.22-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m180.01 250.96-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m213.15 277.15-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m295.47 256.84-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m382.07 232.78-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m298.15 172.38-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m367.64 138.17-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m363.36 121.6-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m320.06 137.1-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m348.39 149.93-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m415.21 104.49-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m452.09 67.608-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m338.77 26.982-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m314.18 46.226-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m266.07 76.695-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m239.35 95.404-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m199.26 124.27-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m127.63 172.91-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m96.087 192.69-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m46.375 211.4-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m39.96 176.12-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m38.891 93.8-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37062 0.01316-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m37.288 64.935-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37062 0.01316-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m73.636 36.07-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37062 0.01316-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m120.68 35.001-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m208.88 39.277-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m227.59 57.452-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m183.75 95.938-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m170.39 102.89-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m132.97 97.542-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m114.8 83.109-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m94.483 88.989-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37062 0.01316-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m75.774 108.77-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m73.636 141.91-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m103.57 157.41-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m121.21 151-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m291.73 69.746-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m276.5 50.9-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m382.14 63.184-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m427.5 42.017-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m377.61 45.797-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m357.95 63.184-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m342.83 95.69-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m311.08 110.05-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m281.6 128.2-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m273.28 147.1-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m249.09 157.68-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m230.19 166.75-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m212.81 186.41-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m199.96 195.48-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m181.06 207.57-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m147.8 225.72-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m132.68 233.28-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m103.19 238.57-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m102.44 222.69-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m106.21 216.65-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m69.93 233.28-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m66.15 258.22-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m38.936 282.41-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m30.62 276.37-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m67.662 230.25-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m99.412 200.77-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m132.68 172.8-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m149.31 150.12-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m165.18 125.93-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m200.71 102.49-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m245.31 80.571-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m310.33 74.523-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m339.05 71.499-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m363.24 75.279-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m339.05 107.03-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m312.59 121.4-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m278.58 144.83-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m233.97 170.53-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m209.78 186.41-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m171.23 215.13-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m119.07 243.1-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m183.33 241.59-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m224.15 204.55-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m271.02 177.34-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m319.4 152.39-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m385.92 106.27-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m429.77 82.839-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m473.61 70.743-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m481.17 69.231-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m439.59 97.958-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m377.61 134.25-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m322.42 163.73-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m280.84 184.14-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m261.19 196.99-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m226.41 217.4-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m176.52 252.17-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m132.68 270.32-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m111.51 277.88-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m156.87 258.22-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m191.64 215.89-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m249.09 181.87-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m293.69 154.66-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m337.54 131.98-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m357.19 127.44-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m348.12 172.8-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m299.74 193.21-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m240.02 212.11-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m190.89 228.74-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m125.12 243.86-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m81.269 246.13-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37063 0.01316-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m165.18 156.17-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m255.14 73.011-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m271.02 60.916-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m385.92 206.06-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m354.17 231.76-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m282.36 275.61-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m230.19 273.34-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m380.63 172.8-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m461.52 150.12-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m434.3 172.04-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m483.44 255.2-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m421.45 282.41-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m342.08 262-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m388.19 299.04-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m100.92 54.112-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m79.001 66.964-0.20407 0.24605-0.32759-0.23964-0.3089-0.10871 0.13821-0.37062 0.01316-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='bStar'/>
        <path d="m508.2 26.797-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m477.1 41.31-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m484.02 21.268-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m504.75 47.53-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m533.78 23.342-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m535.16 66.19-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m512.35 93.835-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m532.39 120.79-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m499.22 151.89-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26078z" className='yStar'/>
        <path d="m530.32 168.47-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m511.66 189.9-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m533.08 221-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m528.94 251.41-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m506.82 283.2-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m497.15 120.79-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m544.14 307.39-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m516.5 326.74-0.20407 0.24605-0.32759-0.23964-0.30889-0.10872 0.13821-0.37062 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m466.74 321.21-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m399.7 326.74-0.20407 0.24605-0.32759-0.23964-0.30889-0.10872 0.13821-0.37062 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m300.87 328.81-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m187.53 328.12-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m136.39 306-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m89.393 330.19-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m25.12 236.2-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m5.7692 107.66-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m7.1515 10.902-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m177.16 5.3728-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m390.72 4.6817-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m93.54 6.0639-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m8.5337 183.68-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26078z" className='yStar'/>
        <path d="m5.0781 335.03-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m459.83 332.96-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m550.36 238.28-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m550.36 130.46-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26078z" className='yStar'/>
        <path d="m541.38 48.222-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m274.61 11.593-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-14.42 29.148-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-16.864 83.881-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-45.208 42.831-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-51.56 98.053-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-51.072 149.85-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m-32.502 184.55-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m-15.886 238.31-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26078z" className='yStar'/>
        <path d="m-71.108 270.56-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m-61.823 307.21-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m-26.149 330.18-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-14.42 276.42-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-63.778 286.2-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m-89.189 314.05-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m-41.298 100.01-0.20407 0.24605-0.32759-0.23965-0.30889-0.1087 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-68.176 16.442-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37062 0.0132-0.31324 0.41299 0.01059 0.31702-0.08488 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-32.013 330.18-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-9.5334 305.74-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-5.1352 320.89-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='yStar'/>
        <path d="m-12.954 337.51-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-34.945 327.74-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26078z" className='yStar'/>
        <path d="m-24.194 304.28-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m-12.954 275.93-0.20407 0.24604-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37714 0.18277 0.26078z" className='yStar'/>
        <path d="m2.1951 283.75-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26078z" className='yStar'/>
        <path d="m35.915 303.3-0.20407 0.24605-0.32759-0.23965-0.30889-0.10871 0.13821-0.37062 0.0132-0.31325 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26078z" className='yStar'/>
        <path d="m-37.877 45.275-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-66.221 106.85-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-39.832 126.4-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-16.375 77.528-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-25.171 160.6-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-78.927 178.2-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-98.474 235.86-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-65.244 287.66-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-26.149 303.3-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-47.651 336.53-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-66.221 306.23-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-41.787 261.27-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-28.103 245.64-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-13.443 303.3-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m-16.375 336.53-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>
        <path d="m34.448 323.83-0.20407 0.24605-0.32759-0.23964-0.30889-0.10871 0.13821-0.37063 0.0132-0.31324 0.41299 0.0106 0.31702-0.0849 0.11704 0.37715 0.18277 0.26077z" className='bStar'/>

        <path d="m121.08 15.252c85.819 140.8 224.95 248.2 382.89 295.58 149.95 44.977 315.56 35.682 459.53-25.794 143.97-61.475 265.22-174.66 336.44-314.08 71.217-139.41 91.862-303.99 57.288-456.68-34.574-152.68-124.11-292.31-248.43-387.45-124.33-95.133-282.51-145.05-438.92-138.5-156.41 6.5455-309.87 69.5-425.82 174.69s-193.5 251.81-215.2 406.85c-21.697 155.04 12.627 317.32 95.243 450.29" className='orbit'/>
        <path d="m317.25 202.74c3.5277 7.4982 7.0555 14.996 6.1108 20.731-0.94472 5.7343-6.3622 9.7047-11.843 11.626s-11.024 1.7933-31.562-7.2369-56.066-26.96-84.162-50.399-48.759-52.385-62.933-66.858-21.859-14.473-28.453-12.522c-6.5935 1.9509-11.927 5.7338-14.762 10.601s-3.0869 10.759-0.69288 14.153c2.3941 3.3943 7.4335 4.2908 11.641 3.9425 4.2075-0.34832 7.8879-2.0854 11.415-3.7503" className='trajectory'/>
        <path d="m35.284 15.63c75.443 130.23 193.03 235.59 330.73 296.33 155.01 68.384 334.6 79.308 496.75 30.218 162.15-49.091 305.53-157.79 396.58-300.67 91.048-142.88 129.02-318.75 105.03-486.46-23.993-167.71-109.75-325.88-237.21-437.5s-295.56-175.76-464.97-177.42c-169.41-1.658-338.74 59.182-468.35 168.28-129.62 109.1-218.45 265.56-245.72 432.77s7.2506 343.79 95.485 488.42" className='orbit'/>
        <g className='rotEarth' style={{transformOrigin: '304.59px 212.18px' }}>
        <circle cx="304.59" cy="212.18" r="16.82" className='earth'/>
        <path d="m309.55 215.24c-5.8586 2.1292 1.4205 3.4365-4.1105 4.6302-9.881 2.1326-1.2847-1.6633-4.8664-3.1183-2.8043-1.1392 1.2627-6.1894 4.394-6.1894s7.5268 2.7151 5.0082 4.394z" className='antarctica'/>
        <g transform="translate(12.983,14.585)" className='earthLand'>
        <path d="m280.91 189.31c0 1.9924 5.0878 3.9431 3.2536 3.9431-1.8343 0-8.6084-2.0626-7.2527-3.8312 1.2201-2.328 4.1736-5.5962 6.0757-6.3233 1.6644-0.63623-2.0765 4.2191-2.0765 6.2115z" />
        <path d="m308.13 194.19c2e-5 2.0558-1.1262-1.5953-3.6576-1.5953-2.5434-1e-3 -5.6238 0.55769-4.4911-1.345 0.83339-1.5553 0.83969-5.5173 3.3712-5.5173 2.3024 1.0806 4.5074 6.485 4.7775 8.4576z" />
        <path d="m299.64 209.29c-1e-5 1.2725-2.1781 2.393-5.0838 3.3616-4.759 1.5864-5.7468-2.0891-5.7469-3.3616 1.3815-4.0675 2.314 0.49103 5.3048 0.49103s4.5864-3.3499 5.5258-0.49103z" />
        <path d="m284.72 202.56c0 0.89137-2.7191 4.3578-3.7008 4.3578-0.98173 0-2.6518-0.4805-2.6518-1.3719s1.3787-0.6456 2.3604-0.6456c0.98172 2e-5 3.9922-3.2316 3.9922-2.3403z" />
        </g>
        </g>

        <path d="m318.38 202.3 2.2384-1.6036 1.5375-0.0611 3.217-2.0926 4.934-3.5926 1.8709-0.46772-0.56793 1.8041-8.2162 6.0494-0.51162 1.4227-2.7166 1.7396 1.9023-2.1584-1.056-1.8918z" className='stratios'/>

        <g className='rotMars' style={{transformOrigin: '106.34px 115.98px' }}>
        <ellipse cx="106.34" cy="115.98" rx="10.205" ry="10.016" className='mars'/>
        <g transform="translate(12.983,14.585)" className='marsLand'>
        <path d="m91.144 93.579c-0.10821 2.1244-3.1582 4.3601-4.2437 4.7036-1.4051 0.44469-3.1246-0.04349-2.7396-1.1377 0.46556-1.3233 2.232-3.515 3.5991-3.9904 1.2163-0.423 3.4613-1.0887 3.3842 0.42451z" />
        <path d="m90.525 102.01c-0.1082 2.1244-3.1582 2.3757-4.2437 2.7192-1.4051 0.44469-3.1246-0.0435-2.7396-1.1377 0.46556-1.3233 2.232-3.515 3.5991-3.9904 1.2163-0.423 3.4613 0.89566 3.3842 2.4089z" />
        <path d="m97.257 101.32c-0.0748 2.5391-3.8841 3.9828-7.3749 4.488-1.0958 0.15857-2.16-0.0521-1.8939-1.3598 0.32184-1.5816 2.6769-5.6186 3.622-6.1869 0.8408-0.50556 5.7001 1.2501 5.6468 3.0587z"/>
        <path d="m102.26 105.67c-0.10821 2.1244-3.1582 4.3601-4.2437 4.7036-1.4051 0.44469-3.1246-0.0435-2.7396-1.1377 0.46556-1.3233 3.6494-6.7278 5.0165-7.2032 1.2163-0.423 1.9668 3.6373 1.9668 3.6373z" />
        </g>
        </g>
        </svg>
        </div>
      );}
    }

    export default App;
